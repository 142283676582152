import type { ReactElement, VFC } from 'react';
import React, { Fragment } from 'react';
import Text from '@clearscore/rainbow.text';
import Button from '@clearscore/rainbow.button';
import Spacer from '@clearscore/rainbow.spacer';
import Stack from '@clearscore/rainbow.stack';
import { useTranslation } from 'react-i18next';

import { PAGE_NAMESPACE } from '../../lib/const';

const LOGIN_URL = '/login';

const ResetPasswordConfirmation: VFC = (): ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);

    return (
        <Fragment>
            <Text.H1>
                <Spacer all={{ bottom: Spacer.spacings.MEDIUM }}>{t('confirmationTitle')}</Spacer>
            </Text.H1>
            <Stack all={Stack.spacings.LARGE}>
                <Text.Body1 dataId="password-confirmation-text">{t('confirmationMessage')}</Text.Body1>
                <Button href={LOGIN_URL} size={Button.sizes.LARGE} dataId="password-confirmation-button">
                    {t('confirmationButton')}
                </Button>
            </Stack>
        </Fragment>
    );
};

export default ResetPasswordConfirmation;
