import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import type { FormRenderProps } from 'react-final-form';
import { withTypes, Field } from 'react-final-form';
import normalizeEmail from '@clearscore/normalize.email';
import { isRequiredValidation } from '@clearscore/validation.is-required';
import { isEmailValidation } from '@clearscore/validation.is-email';
import FormLayout from '@clearscore/rainbow.form-layout';
import Text from '@clearscore/rainbow.text';
import Button from '@clearscore/rainbow.button';
import Label from '@clearscore/rainbow.label';
import Spacer from '@clearscore/rainbow.spacer';
import TextInput from '@clearscore/rainbow.text-input';
import { useTranslation } from 'react-i18next';

import { PAGE_NAMESPACE } from '../../lib/const';

export interface IResetPasswordFormValues {
    email: string;
}

interface IResetPasswordFormProps {
    handleReset: (values: IResetPasswordFormValues) => void;
    isResetPending: boolean;
}

const { Form } = withTypes<IResetPasswordFormValues>();

const ResetPasswordForm = ({ handleReset, isResetPending }: IResetPasswordFormProps): ReactElement => {
    const { t } = useTranslation(PAGE_NAMESPACE);

    return (
        <React.Fragment>
            <Text.H1>
                <Spacer all={{ bottom: Spacer.spacings.MEDIUM }}>{t('mainTitle')}</Spacer>
            </Text.H1>
            <Form
                name="ResetPasswordForm"
                onSubmit={handleReset}
                render={({ handleSubmit, pristine, invalid }: FormRenderProps<IResetPasswordFormValues>): ReactNode => (
                    <form onSubmit={handleSubmit}>
                        <FormLayout>
                            <FormLayout.Fieldset>
                                <FormLayout.Item>
                                    <Label htmlFor="email">{t('email.label')}</Label>
                                    <Field
                                        name="email"
                                        type="email"
                                        parse={normalizeEmail}
                                        validate={(data): boolean =>
                                            isRequiredValidation()(data) || isEmailValidation()(data)
                                        }
                                    >
                                        {({ input, meta }): ReactElement => (
                                            <TextInput
                                                id="email"
                                                isLightBg
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                value={input.value}
                                                onBlur={input.onBlur}
                                                onChange={input.onChange}
                                                isValid={meta.touched && meta.valid}
                                                isInvalid={meta.touched && meta.invalid}
                                                placeholder={t('email.placeholder')}
                                                errorMessage={(meta.touched && meta.error) || ''}
                                            />
                                        )}
                                    </Field>
                                </FormLayout.Item>
                                <FormLayout.Item>
                                    <Button
                                        htmlType="submit"
                                        isNarrow
                                        isResponsive
                                        type={Button.types.PRIMARY}
                                        size={Button.sizes.LARGE}
                                        isLoading={isResetPending}
                                        isDisabled={pristine || isResetPending || invalid}
                                    >
                                        {t('reset')}
                                    </Button>
                                </FormLayout.Item>
                                <FormLayout.Item>
                                    <Text.Link1 dataId="already-know-password-link" href="/login">
                                        {t('alreadyKnowPassword')}
                                    </Text.Link1>
                                </FormLayout.Item>
                            </FormLayout.Fieldset>
                        </FormLayout>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default ResetPasswordForm;
