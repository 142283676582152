import type { ReactElement } from 'react';
import React from 'react';
import RainbowContainer from '@clearscore/rainbow.container';
import getDevice from '@clearscore/helpers.device';
import useCsAuthMutateQuery from '@clearscore-group/ui.external-cs-auth.cs-mutate-query';
import { EN_GB } from '@clearscore/config.i18n';
import { useTranslation } from 'react-i18next';

import type { IResetPasswordFormValues } from './components/reset-password-form';
import ResetPasswordForm from './components/reset-password-form';
import Confirmation from './components/reset-password-confirmation';
import { loginService, PAGE_NAMESPACE } from './lib/const';
import copy from './copy/pages/reset-password/en-gb/copy.json';

const device = getDevice();

interface IUseResetReturn {
    handleReset: (values: IResetPasswordFormValues) => void;
    isResetPending: boolean;
    showConfirmation: boolean;
}

const useReset = (): IUseResetReturn => {
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const { mutate, isLoading } = useCsAuthMutateQuery(loginService);
    const handleReset = (values: IResetPasswordFormValues): void => {
        mutate({ ...values, device }, { onSuccess: () => setShowConfirmation(true) });
    };
    return { handleReset, isResetPending: isLoading, showConfirmation };
};

const ResetPassword = (): ReactElement => {
    const { handleReset, isResetPending, showConfirmation } = useReset();
    const { i18n } = useTranslation(PAGE_NAMESPACE);

    i18n.addResourceBundle(EN_GB, PAGE_NAMESPACE, copy);

    return (
        <RainbowContainer size={RainbowContainer.Size.SMALL}>
            {showConfirmation ? (
                <Confirmation />
            ) : (
                <ResetPasswordForm handleReset={handleReset} isResetPending={isResetPending} />
            )}
        </RainbowContainer>
    );
};

export default ResetPassword;
